import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
    <div style={{ display: "flex", justifyContent: "center" }}>
      <img
        style={{ width: "200px", marginBottom: "20px" }}
        src="https://hekimzade.com/cdn/shop/files/hekimzade_logo_banner-removebg-preview_155x@2x.png?v=1646230568"
      ></img>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
