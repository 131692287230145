import React, { useState } from "react";

export default function App() {
  const questions = [
    {
      questionText: "Cildinizde sivilcelenme mi var?",
      answerOptions: [
        { answerText: "Evet", isMacunCorrect: false, isKremCorrect: true },
        { answerText: "Biraz", isMacunCorrect: false, isKremCorrect: true },
        { answerText: "Çok Fazla", isMacunCorrect: false, isKremCorrect: true },
        { answerText: "Hayır", isMacunCorrect: false, isKremCorrect: false },
      ],
    },
    {
      questionText: "Hazımsızlık şikayetiniz var mı?",
      answerOptions: [
        {
          answerText: "Evet",
          isMacunCorrect: true,
          isKremCorrect: false,
        },
        { answerText: "Biraz", isMacunCorrect: true, isKremCorrect: false },
        {
          answerText: "Çok Fazla",
          isMacunCorrect: true,
          isKremCorrect: false,
        },
        {
          answerText: "Hayır",
          isMacunCorrect: false,
          isKremCorrect: false,
        },
      ],
    },
    {
      questionText: "Uyuma Probleminiz Varmı?",
      answerOptions: [
        { answerText: "Evet", isMacunCorrect: true, isKremCorrect: false },
        {
          answerText: "Çok Fazla",
          isMacunCorrect: true,
          isKremCorrect: false,
        },
        { answerText: "Biraz", isMacunCorrect: true, isKremCorrect: false },
        {
          answerText: "Hayır",
          isMacunCorrect: false,
          isKremCorrect: false,
        },
      ],
    },
    {
      questionText: "Cilt Kuruluğuna Sahip Misiniz",
      answerOptions: [
        { answerText: "Evet", isMacunCorrect: false, isKremCorrect: true },
        {
          answerText: "Biraz",
          isCoisMacunCorrectrrect: false,
          isKremCorrect: true,
        },
        {
          answerText: "Çok fazla",
          isMacunCorrect: false,
          isKremCorrect: true,
        },
        { answerText: "Hayır", isMacunCorrect: false, isKremCorrect: false },
      ],
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [macunscore, setMacunScore] = useState(0);

  const [kremscore, setKremScore] = useState(0);

  var targetElement = document.getElementById("image-macun");
  var targetElement2 = document.getElementById("image-krem");
  var targetElement4 = document.getElementById("text-product1");
  var targetElement5 = document.getElementById("text-product2");
  var targetElement6 = document.getElementById("text-product3");
  var targetElement7 = document.getElementById("image-sivilce");
  var targetElement8 = document.getElementById("image-hazim");
  var targetElement9 = document.getElementById("image-uyku");
  var targetElement10 = document.getElementById("image-kuru");
  var targetElement11 = document.getElementById("button-product1");
  var targetElement12 = document.getElementById("button-product2");

  const handleAnswerOptionClick = (isMacunCorrect) => {
    if (isMacunCorrect) {
      setScore(score + 1);
      setMacunScore(macunscore + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };
  const handleAnswerOptionClickKrem = (isKremCorrect) => {
    if (isKremCorrect) {
      setScore(score + 1);
      setKremScore(kremscore + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
    if (macunscore > kremscore && currentQuestion > 2) {
      targetElement.style.display = "flex";
      targetElement5.style.display = "flex";
      targetElement12.style.display = "flex";
    }
    if (macunscore < kremscore && currentQuestion > 2) {
      targetElement2.style.display = "flex";
      targetElement4.style.display = "flex";
      targetElement11.style.display = "flex";
    }
    if (currentQuestion > 2) {
      targetElement6.style.display = "flex";
    }

    if (currentQuestion === 0) {
      document.getElementById("image-hazim").style.display = "flex";
      document.getElementById("image-sivilce").style.display = "none";
    }

    if (currentQuestion === 1) {
      targetElement7.style.display = "none";
      targetElement8.style.display = "none";

      targetElement9.style.display = "flex";

      targetElement10.style.display = "none";
    }
    if (currentQuestion === 2) {
      targetElement7.style.display = "none";
      targetElement8.style.display = "none";

      targetElement9.style.display = "none";

      targetElement10.style.display = "flex";
    }
    if (currentQuestion === 3) {
      targetElement7.style.display = "none";
      targetElement8.style.display = "none";

      targetElement9.style.display = "none";

      targetElement10.style.display = "none";
    }
  };

  return (
    <div className="app">
      <img
        style={{ display: "none", border: "double" }}
        id="image-krem"
        className="image-anket"
        src="https://hekimzade.com/cdn/shop/files/image001_300x.jpg?v=1703318966"
        alt="Açıklama"
      />
      <img
        style={{ display: "none", border: "double" }}
        id="image-macun"
        className="image-anket"
        src="https://hekimzade.com/cdn/shop/files/urun-07_75a61772-0e55-4104-9de5-64cbb2162ea6_1200x.jpg?v=1701948671"
        alt="Açıklama"
      />
      <img
        style={{ border: "double" }}
        id="image-sivilce"
        className="image-anket"
        src="https://evdesaglik.memorial.com.tr/wp-content/uploads/2023/03/evde-sivilce-nasil-gecer.jpg"
        alt="Açıklama"
      />
      <img
        style={{ display: "none", border: "double" }}
        id="image-hazim"
        className="image-anket"
        src="https://www.medicalpark.com.tr/_uploads/_images/_healthGuide/VvV82ruk.jpg"
        alt="Açıklama"
      />{" "}
      <img
        style={{ display: "none", border: "double" }}
        id="image-uyku"
        className="image-anket"
        src="https://www.koruhastanesi.com/images/haber/main/2332021123947621.jpg"
        alt="Açıklama"
      />
      <img
        style={{ display: "none", border: "double" }}
        id="image-kuru"
        className="image-anket"
        src="https://www.edoktortv.com/wp-content/uploads/2020/12/cilt-kurumasi.jpg"
        alt="Açıklama"
      />
      <div>
        <h3 style={{ display: "none" }} id="text-product3">
          Önerilen Ürün
        </h3>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 id="text-product1" style={{ display: "none" }}>
          Vardamin Krem
        </h1>
        <a href="https://hekimzade.com/products/vardamin-krem-gece-kullanim-icin-bacak-bakim-kremi-200ml">
          <button
            style={{
              display: "none",
              textAlign: "center",
              justifyContent: "center",
            }}
            id="button-product1"
          >
            Ürüne Git
          </button>
        </a>

        <h1
          id="text-product2"
          style={{
            display: "none",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          Tafranex 90 Kapsül
        </h1>
        <a href="https://hekimzade.com/products/tafranex-90-kapsul-750mg-kedi-otu-iceren-takviye-edici-gida">
          <button
            style={{
              display: "none",
              textAlign: "center",
            }}
            id="button-product2"
          >
            Ürüne Git
          </button>
        </a>
      </div>
      <div></div>
      {showScore ? (
        <div>
          <div></div>
          <div style={{ display: "none" }} className="score-section">
            %{macunscore * 25} takviye gıdaya uygun, %{kremscore * 25} kreme
            uygun
          </div>
        </div>
      ) : (
        <>
          <div className="question-section">
            <div className="question-count">
              <span>Soru {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className="question-text">
              {questions[currentQuestion].questionText}
            </div>
          </div>
          <div className="answer-section">
            {questions[currentQuestion].answerOptions.map((answerOption) => (
              <button
                className="button"
                onClick={() => {
                  handleAnswerOptionClick(answerOption.isMacunCorrect);
                  handleAnswerOptionClickKrem(answerOption.isKremCorrect);
                }}
              >
                {answerOption.answerText}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
